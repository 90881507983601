import { addDays } from 'date-fns'
import z from 'zod'
import { roundTo2 } from '../helperFunctions/number'
import { mobileOrPhoneType } from './generic'
import { InternalSaleListingSchema } from './saleListing'
import { TypesenseListingSchema } from './typesense'
import { VendorSchema } from './vendor'

export const TransactionConfirmationSchema = z.object({
    time: z.number(),
    userId: z.string(),
})

export const TransactionSchema = z.object({
    id: z.string(),
    date: z.string().date().optional(),
    amount: z.number().gt(0),
    type: z.enum(['credit', 'debit']),
    description: z.union([
        z.enum(['Deposit', 'Vendor Balance', 'Marketing', 'Office Commission']),
        z.string(),
    ]),
    paid: TransactionConfirmationSchema.optional(),
    externalRef: z
        .enum([
            'marketing',
            'deposit1',
            'deposit2',
            'agentCommission',
            'officeCommission',
            'additionalCharge',
            'vendorBalance',
            'conjunctionalAgreement',
        ])
        .optional(),
})

const SolicitorSchema = z.object({
    name: z.string().optional(),
    contact: z.string().optional(),
    email: z.string().email().optional(),
    mobileNumber: mobileOrPhoneType.optional(),
})

export type SolicitorSchema = z.infer<typeof SolicitorSchema>

const ConditionSchema = z.object({
    condition: z.string(),
    //conditionDate: z.string().date(),
    dueDaysFromContract: z.number(),
    fulfilled: z.boolean().default(false),
    special: z.enum(['requiresFinance', 'buildingAndPestRequired']).optional(),
})

const BuyersAgentSchema = z.object({
    name: z.string(),
    abn: z.string().optional(),
    licenceNumber: z.string().optional(),
    mobileNumber: mobileOrPhoneType.or(z.literal('')).optional(),
    email: z.string().email().or(z.literal('')).optional(),
})

export const ListingContractSchema = z
    .object({
        salePrice: z.number(),
        commissionOverride: z.boolean().optional(),
        commissionOverrideValue: z.number().optional(),
        contractDate: z.string().date().optional(),
        initialDeposit: z.number(),
        initialDepositDue: z.number(),
        settlementDateDays: z.number().default(30),
        buyers: z.array(VendorSchema),
        vendorSolicitor: SolicitorSchema.optional(),
        buyerSolicitor: SolicitorSchema.optional(),
        hasBuyersAgent: z.boolean().default(false),
        buyersAgent: BuyersAgentSchema.optional(),
        additionalDetails: z.record(z.string(), z.string().optional()).optional(),
        contractConditions: z.array(ConditionSchema).default([]),
        requiresFinance: z.boolean().default(false),
        buildingAndPestRequired: z.boolean().default(false),
        balanceDeposit: z.number().nullable().optional(),
        balanceDepositDue: z.number().nullable().optional(),
    })
    .refine(
        ({ balanceDeposit, balanceDepositDue }) =>
            (balanceDeposit == undefined) == (balanceDepositDue == undefined),
        {
            message: 'Balance deposit due date is required if balance deposit is set',
            path: ['balanceDepositDue', 'balanceDeposit'],
        },
    )

export const UpdateLockedContractSchema = z.object({
    contractDate: z.string().date().optional(),
})

export type UpdateLockedContractSchema = z.infer<typeof UpdateLockedContractSchema>

export type ListingContractSchema = z.infer<typeof ListingContractSchema>
export type TransactionSchema = z.infer<typeof TransactionSchema>
export type InternalTransactionSchema = TransactionSchema & {
    xeroInvoice?: {
        id: string
        invoiceNumber?: string
        url: string
        status: string
    }
}
export type TransactionConfirmationSchema = z.infer<typeof TransactionConfirmationSchema>

export const getSettlementDate = (contract?: ListingContractSchema) =>
    contract && contract.contractDate && contract.settlementDateDays
        ? addDays(new Date(contract.contractDate), contract.settlementDateDays)
        : undefined

export const getContractCommission = (listing: TypesenseListingSchema | InternalSaleListingSchema) =>
    listing.contract?.commissionOverride
        ? (listing.contract.commissionOverrideValue ?? 0)
        : roundTo2(((listing.contract?.salePrice ?? 0) * (listing.commission ?? 0)) / 100)
