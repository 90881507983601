import { formatAddress } from '@fastre/core/src/schemas/generic'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    Link,
    Modal,
    ModalClose,
    ModalDialog,
    Stack,
    Textarea,
    Typography,
} from '@mui/joy'
import { useApi } from 'api'
import { useUserData } from 'auth'
import Input from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useShowSnack } from 'components/snackbar'
import { prop } from 'ramda'
import { useEffect, useState } from 'react'
import { useListingContext } from '../listingProvider'

const EmailToVendorModal = ({ open, closeModal }: { open: boolean; closeModal: () => void }) => {
    const api = useApi()
    const { listing } = useListingContext()
    const showSnack = useShowSnack()
    const { user } = useUserData()

    const [emailSubject, setEmailSubject] = useState('')
    const [emailBody, setEmailBody] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!open) {
            setEmailSubject('Marketing Invoice')
            setEmailBody(`Hi ${listing.vendors[0].firstName},
Please find attached the marketing invoice for your listing ${formatAddress(listing.listingAddress)}.
Kind Regards,
${user.firstName}`)
        }
    }, [open])

    return (
        <Modal
            open={open}
            onClose={dontCloseOnBackgroundClick(closeModal)}
        >
            <ModalDialog
                sx={{
                    overflow: 'auto',
                }}
            >
                <ModalClose sx={{ zIndex: 3 }} />
                <DialogTitle>Email Vendor Marketing Invoice</DialogTitle>
                <DialogContent
                    sx={{
                        width: '810px',
                    }}
                >
                    <Input
                        label="Subject"
                        value={emailSubject}
                        onChange={setEmailSubject}
                    />
                    <FormControl>
                        <FormLabel>Body</FormLabel>
                        <Textarea
                            value={emailBody}
                            onChange={x => setEmailBody(x.target.value)}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={loading}
                        onClick={async () => {
                            try {
                                setLoading(true)
                                await api.post(`/listings/${listing.listingId}/sendmarketinginvoiceemail`, {
                                    subject: emailSubject,
                                    body: emailBody,
                                })
                                closeModal()
                            } catch (e) {
                                showSnack('Failed to send email', 'danger')
                            } finally {
                                setLoading(false)
                            }
                        }}
                    >
                        Send
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={closeModal}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}

export default () => {
    const api = useApi()
    const { user } = useUserData()
    const { listing, setListing } = useListingContext()
    const showSnack = useShowSnack()

    const [loading, setLoading] = useState(false)
    const [emailModalOpen, setEmailModalOpen] = useState(false)

    if (listing.marketingPaymentInvoice) {
        return (
            <>
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                >
                    <Box sx={{ height: '36px', display: 'flex', alignItems: 'center' }}>
                        <Link onClick={() => window.open(listing.marketingPaymentInvoice?.url, '_blank')}>
                            {listing.marketingPaymentInvoice.invoiceNumber}
                        </Link>
                        {listing.marketingPaymentInvoice.status == 'PAID' && (
                            <Typography sx={{ whiteSpace: 'pre' }}> (Paid)</Typography>
                        )}
                    </Box>
                    <Button
                        variant="soft"
                        onClick={() => setEmailModalOpen(true)}
                        disabled={listing.vendors.length === 0}
                    >
                        Email Vendor
                    </Button>
                </Stack>
                <EmailToVendorModal
                    open={emailModalOpen}
                    closeModal={() => setEmailModalOpen(false)}
                />
            </>
        )
    }

    if (!user.permissions.includes('listings.fullControl')) {
        return undefined
    }

    return (
        <Button
            loading={loading}
            disabled={!user.xeroConnected}
            variant="soft"
            onClick={async () => {
                setLoading(true)
                try {
                    const newListing = await api
                        .post(`/listings/${listing.listingId}/xero/marketinginvoice`)
                        .then(prop('data'))
                    setListing({ ...listing, marketingPaymentInvoice: newListing.marketingPaymentInvoice })
                    showSnack('Xero Invoice Created', 'success')
                } catch (e) {
                    console.error(e)
                    showSnack('Failed to create Xero Invoice', 'danger')
                }
                setLoading(false)
            }}
        >
            Create Xero Invoice
        </Button>
    )
}
